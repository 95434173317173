import { App } from 'vue'
import { setupDevtools } from './devtools'
import { configureAuthorizationHeaderInterceptor } from './interceptors'
import { configureNavigationGuards } from './navigationGuards'
import { AuthOptions, AuthPlugin, AuthProvider, RequiredAuthOptions } from './types'
import { setupAuthPluginAuth0 } from './providers/auth0'
import { setupAuthPluginMSAL } from './providers/msal'
import { setupAuthPluginOAuth2 } from './providers/oauth2'
import { saiftyProvider } from './providers/useProviderData'
import { setupAuthPluginToken } from './providers/token'

export let authInstance: AuthPlugin | undefined = undefined

let localAppOptions: RequiredAuthOptions | undefined = undefined

const defaultOptions = {
  autoConfigureNavigationGuards: true,
  defaultPictureURL: '/user.svg',
}

export async function changeAuthInstance(newProvider: AuthProvider) {
  if (localAppOptions) {
    authInstance = undefined

    if (newProvider.name === 'msal') {
      authInstance = setupAuthPluginMSAL(localAppOptions, newProvider.config)
    } else if (newProvider.name === 'auth0') {
      authInstance = setupAuthPluginAuth0(localAppOptions, newProvider.config)
    } else if (newProvider.name === 'token') {
      authInstance = setupAuthPluginToken(localAppOptions, newProvider.config)
    } else {
      authInstance = setupAuthPluginOAuth2(localAppOptions, newProvider.config)
    }

    // Note: We don't need to update the 'app.config.globalProperties.$auth' plugin value, because we only use this function to change the authInstance to redirect to the selected provider, after the user logged in, he will be redirect to the app again and the plugin will be loaded with the correct latest authInstance

    if (import.meta.env.DEV) {
      // @ts-expect-error: until it gets fixed in devtools
      setupDevtools(app, authInstance)
    }
  }
}

export function createAuth(appOptions: AuthOptions) {
  const options: RequiredAuthOptions = { ...defaultOptions, ...appOptions }

  return {
    install: (app: App): void => {
      localAppOptions = options

      if (options.provider.name === 'msal') {
        authInstance = setupAuthPluginMSAL(options, options.provider.config)
      } else if (options.provider.name === 'auth0') {
        authInstance = setupAuthPluginAuth0(options, options.provider.config)
      } else if (options.provider.name === 'token') {
        authInstance = setupAuthPluginToken(options, options.provider.config)
      } else {
        authInstance = setupAuthPluginOAuth2(options, options.provider.config)
      }

      app.config.globalProperties.$auth = authInstance

      if (options.autoConfigureNavigationGuards) {
        configureNavigationGuards(options.router)
      }

      if (options.axios?.autoAddAuthorizationHeader) {
        configureAuthorizationHeaderInterceptor(options.axios.instance, options.axios.authorizationHeaderPrefix)
      }

      if (import.meta.env.DEV) {
        // @ts-expect-error: until it gets fixed in devtools
        setupDevtools(app, authInstance)
      }
    },
  }
}
