import axios from 'axios'
import { addToast } from '@/composables/useToasts'
import { useAuth } from '@/auth'
import { useThrottleFn } from '@vueuse/core'
import useAdminTools from '@/composables/useAdminTools'
import useRateLimitingModal from '@/composables/useRateLimitingModal'

const axiosInstance = axios.create()

const { tenantIDForWhichToActOnBehalf, canActOnBehalfOfOtherTenants } = useAdminTools()
const { openRateLimitingModal } = useRateLimitingModal()

const sessionExpired = useThrottleFn(() => {
  // We throttle the session expired function for at most 1 time per 2 seconds because multiple requests can fail at the same time
  addToast({ text: 'Your token is expired.' })

  // TODO: Disabled for app, token based auth
  // const auth = useAuth()
  // If the request fails with 401 error, the token has expired so, we logout
  // auth.logout()
}, 2000)

const freeVersionExceeded = useThrottleFn(() => {
  // We throttle the free version exceeded function to at most 1 time per 2 seconds because multiple requests can return the same backend status code
  openRateLimitingModal()
}, 2000)

axiosInstance.interceptors.request.use((config) => {
  config.params = config.params ?? {}

  if (canActOnBehalfOfOtherTenants() && tenantIDForWhichToActOnBehalf.value) {
    config.params.tenant_id = tenantIDForWhichToActOnBehalf.value
  }
  return config
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response?.status === 429) {
      freeVersionExceeded()
    } else if (error.response?.status === 401) {
      sessionExpired()
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
