import { reactive, readonly } from 'vue'
import { AuthPlugin, RequiredAuthOptions } from '../types'
import useProviderData, { saiftyProvider } from './useProviderData'
import { closeSocketConnection } from '@/composables/useRSockets'
import { addToast } from '@/composables/useToasts'
import { baseURL } from '../../../package.json'

export interface TokenBasedConfiguration {
  tokenLocation: 'localStorage' | 'sessionStorage'
  logoutURL: string
}

const STORAGE_ACCESS_TOKEN_KEY = 'SAIFTY_ACCESS_TOKEN'

export function setupAuthPluginToken(options: RequiredAuthOptions, tokenConfig: TokenBasedConfiguration): AuthPlugin {
  const router = options.router
  const { isAuthenticated, user, error, loaded, waitUntilLoaded, getDefaultAppPermissions } = useProviderData()
  initialize()

  // This function is autocalled, this is a workaround to use await without making the entire composable async
  async function initialize() {
    error.value = undefined
    try {
      await loadUserState()
    } catch (exception) {
      console.error('Token authentication error:', exception)
      error.value = String(exception)
    }
    // When the load of the user state or the authorization code flow fails we assume the user is not authenticated,
    // so that the application gets displayed instead of remaining unloaded
    // public pages would be displayed but private pages would get redirected to the error page
    loaded.value = true
  }

  async function loadUserState(fromRedirectCallback = false) {
    const token = await getAccessToken()
    if (token) {
      isAuthenticated.value = true
      user.value = {
        nickname: 'User',
        just_registered: false,
        id: 'no-id',
        email: 'no-email',
        picture: baseURL + 'img/user.svg',
        permissions: getDefaultAppPermissions(),
      }
    } else {
      isAuthenticated.value = false
      return
    }
  }

  async function logout() {
    // Even if the token revoke fails we assume the user is logged out
    localStorage.removeItem(STORAGE_ACCESS_TOKEN_KEY)
    closeSocketConnection()
    isAuthenticated.value = false
    user.value = undefined
    saiftyProvider.value = undefined
    // Wait for the localStorage to be cleared for saiftyProvider
    setTimeout(() => {
      window.location.href = tokenConfig.logoutURL
    }, 200)
  }

  async function loginWithRedirect() {
    addToast({ text: 'Not authenticated', variant: 'warning' })
  }

  async function signup() {
    addToast({ text: 'Not implemented', variant: 'warning' })
  }

  async function getAccessToken() {
    return localStorage.getItem(STORAGE_ACCESS_TOKEN_KEY) || ''
  }

  async function changePassword() {
    addToast({ text: 'Not implemented', variant: 'warning' })
  }

  const unWrappedRefs = reactive({
    isAuthenticated,
    user,
    loaded,
    error,
    loginWithRedirect,
    signup,
    logout,
    getAccessToken,
    waitUntilLoaded,
    changePassword,
  })

  return readonly(unWrappedRefs)
}
