import '@/assets/scss/app.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'vue-multiselect/dist/vue-multiselect.css'
import { createApp } from 'vue'
import { createAuth } from './auth'
import App from './App.vue'
import router from './router'
import axiosInstance from './api/axios'
import i18n from './plugins/i18n'
import { defaultAuthProvider, getConfigByProvider } from './authConfig'
import { configureNavigationGuards } from './navigationGuards'
import { Buffer } from 'buffer'
import { saiftyProvider } from './auth/providers/useProviderData'

const urlParams = new URLSearchParams(window.location.search)
const queryParamProvider = urlParams.get('provider')

// TODO: change this but don't use the same localStorage for saiftyProvider
const providerToUse = defaultAuthProvider

const auth = createAuth({
  router,
  autoConfigureNavigationGuards: true,
  provider: providerToUse,
  defaultPictureURL: '/img/user.svg',
  axios: {
    instance: axiosInstance,
    autoAddAuthorizationHeader: true,
  },
})

configureNavigationGuards(router)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.Buffer = Buffer

const app = createApp(App)
app.use(router)
app.use(auth)
app.use(i18n)
app.provide('enable-route-transitions', true)
app.mount('#app')
